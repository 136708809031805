<script>
import { computed } from 'vue'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseState from '/~/components/base/state/base-state.vue'
import EmptyState from '/~/components/empty-state/empty-state.vue'
import { useActivity } from '/~/composables/activity'
import ActivityListItemDesk from './activity-list-item.desk.vue'
import ActivityListItemMobile from './activity-list-item.mobile.vue'

export default {
  name: 'activity-list',
  components: {
    ActivityListItemDesk,
    ActivityListItemMobile,
    BaseButton,
    BaseState,
    BaseLoader,
    EmptyState,
  },
  setup() {
    const { activity, recordGroupsByDate } = useActivity()

    const emptyTransactionsTitle = 'No transactions listed yet'
    const emptyTransactionsDescription =
      'You will be able to view your transaction history and print tax invoices for your payment activity.'

    const empty = computed(() => {
      return activity.value.isEmpty
    })

    const allLoaded = computed(() => {
      return activity.value.allRecordsLoaded
    })

    const component = computed(() =>
      ui.mobile ? ActivityListItemMobile : ActivityListItemDesk
    )

    activity.value.refresh()

    function loadMore(isLoaderVisible) {
      if (isLoaderVisible) {
        activity.value.next()
      }
    }

    return {
      emptyTransactionsTitle,
      emptyTransactionsDescription,
      empty,
      allLoaded,
      recordGroupsByDate,
      component,
      loadMore,
    }
  },
}
</script>

<template>
  <div
    class="p-5"
    :class="{
      'my-auto': empty,
    }"
  >
    <div v-if="!empty" class="w-full space-y-5 pb-10">
      <div v-for="group in recordGroupsByDate" :key="group.id">
        <div class="mb-[15px] font-bold text-eonx-neutral-600">
          {{ group.name }}
        </div>
        <div
          class="-mx-2.5 space-y-5 overflow-hidden rounded-lg bg-white p-2.5 sm:space-y-0"
        >
          <template v-for="record in group.records">
            <component :is="component" :key="record.id" :item="record" />
          </template>
        </div>
      </div>
      <base-loader
        v-if="!allLoaded"
        v-observe-visibility="loadMore"
        class="mt-[30px]"
        fullwidth
      />
    </div>
    <empty-state
      v-else
      icon="heroicons/outline/building-storefront"
      class="m-auto"
    >
      <template #title>
        {{ emptyTransactionsTitle }}
      </template>
      <template #description>
        {{ emptyTransactionsDescription }}
      </template>
    </empty-state>
  </div>
</template>
