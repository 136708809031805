<script setup lang="ts">
import { computed } from 'vue'
import { useOnlineOffers } from '/~/extensions/online-offers/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import { Activity, useActivityListItem } from '/~/composables/activity'
import { useCms } from '/~/composables/cms/use-cms'
import { usePointsPrograms } from '/~/templates/bill-payments/composables'

const props = defineProps<{ item: Activity }>()
const { findPartner } = usePointsPrograms()

const { isOnlineOffersEnabled } = useOnlineOffers()
const { ewalletLabels } = useCms()

const selectedPartner = computed(() => findPartner(props.item.subtype))

const {
  amount,
  canSeeDetails,
  cashback,
  cashbackTooltipLabel,
  date,
  icon,
  iconColor,
  label,
  logo,
  paymentMethods,
  pointsTransactions,
  productDetails,
  showStatus,
  subtitle,
  title,
  showPoints,
  airlinePoints,
} = useActivityListItem(props)
</script>

<template>
  <base-link
    :to="{ name: 'activity-details', params: { id: item.id } }"
    :active="canSeeDetails"
    :aria-label="label"
    class="block"
    :class="{
      'text-neutral-400': !canSeeDetails,
    }"
  >
    <div
      class="flex items-start space-x-5 bg-white px-[15px] py-5 pl-5 transition-colors duration-150 ease-in-out hover:bg-blue-100"
    >
      <div class="h-12 w-12 shrink-0">
        <img v-if="logo" class="h-full w-full" :src="logo" alt="" />
        <div
          v-else
          class="flex h-full w-full items-center justify-center rounded-full"
          :class="iconColor"
        >
          <base-icon :svg="icon" />
        </div>
      </div>

      <div
        class="flex w-1/4 min-w-32 grow flex-col justify-center"
        data-testid="title-column"
      >
        <h3
          class="truncate text-base font-bold text-eonx-neutral-800"
          :title="title"
        >
          {{ title }}
        </h3>
        <div
          class="flex items-center truncate text-sm font-semibold leading-tight text-eonx-neutral-600"
        >
          <span :class="[item.statusLabel.color]" class="mr-[5px]">
            {{ item.statusLabel.text }}
          </span>
          <span v-if="showStatus" class="mr-px text-eonx-neutral-800">#</span>
          <span v-if="subtitle" class="text-eonx-neutral-800">
            {{ subtitle }}
          </span>
          <span v-if="item.orderNumber" class="truncate text-eonx-neutral-800">
            {{ item.orderNumber }}
          </span>
        </div>
      </div>

      <template v-if="item.isTypePointsTransferRequest">
        <div class="w-2/12" data-testid="date-column">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">Date</p>
          <p class="text-eonx-neutral-800">
            {{ date }}
          </p>
        </div>

        <div class="w-2/12" data-testid="points-column">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">Points Amount</p>
          <p class="text-eonx-neutral-800">
            {{ props.item.points }}
          </p>
        </div>

        <div class="w-2/12" data-testid="source-destination-column">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">
            {{ props.item.pointsTransferRequest?.descriptionLabel }}
          </p>
          <p class="text-eonx-neutral-800">
            {{ props.item.pointsTransferRequest?.descriptionValue }}
          </p>
        </div>

        <div class="w-4/12" data-testid="reason-column">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">Reason</p>
          <p class="leading-none text-eonx-neutral-800">
            {{ props.item.pointsTransferRequest?.reason }}
          </p>
        </div>

        <div class="!ml-0 h-full !w-5 shrink-0"></div>
      </template>

      <template v-else-if="!item.isTypeUserCommunication">
        <div class="w-2/12">
          <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
            {{ item.isTypeCampaign ? 'Departure Date' : 'Date' }}
          </p>
          <p class="text-eonx-neutral-800">
            {{ date }}
          </p>
        </div>
        <div v-if="item.isTypeProgramOrder" class="w-2/12">
          <p class="mb-[5px] text-sm text-eonx-neutral-600">
            {{ selectedPartner?.programCurrency }}
          </p>
          <p class="text-eonx-neutral-800">
            {{ airlinePoints }}
          </p>
        </div>
        <div v-else class="w-2/12">
          <template v-if="!item.isTypeMembership">
            <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
              Amount
            </p>
            <p class="text-eonx-neutral-800">
              {{ amount }}
            </p>
          </template>
        </div>
        <div class="w-2/12">
          <template v-if="showPoints">
            <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
              Points
            </p>
            <p class="flex flex-col whitespace-nowrap text-eonx-neutral-800">
              <span
                v-for="(pointsTransaction, key) in pointsTransactions"
                :key="key"
              >
                {{ pointsTransaction }}
              </span>
            </p>
          </template>
        </div>
        <div class="w-2/12">
          <template v-if="isOnlineOffersEnabled">
            <div
              class="mb-[5px] flex items-center text-sm leading-none"
              :class="{
                'text-orange-600': cashbackTooltipLabel,
                'text-eonx-neutral-600': !cashbackTooltipLabel,
              }"
            >
              <base-icon
                v-if="cashbackTooltipLabel"
                v-tooltip="
                  `Cashback will be deposited in your ${ewalletLabels.ewalletCash} in 90 days from the transaction date`
                "
                :size="14"
                class="mr-px"
                svg="plain/info-circle"
              />
              <div>Cashback</div>
            </div>
            <p
              class="flex flex-col whitespace-nowrap"
              :class="{
                'text-orange-600': cashbackTooltipLabel,
                'text-eonx-neutral-800': !cashbackTooltipLabel,
              }"
            >
              {{ cashback }}
            </p>
          </template>
        </div>
        <div class="hidden w-2/12 lg:block">
          <template v-if="paymentMethods">
            <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
              Payment method
            </p>
            <p class="text-eonx-neutral-800">
              {{ paymentMethods }}
            </p>
          </template>
          <template v-else-if="item.isTypeCampaign">
            <p class="mb-[5px] text-sm leading-none text-eonx-neutral-600">
              Product Details
            </p>
            <div class="text-eonx-neutral-800" v-html="productDetails" />
          </template>
        </div>
      </template>

      <div class="ml-2.5 w-5 shrink-0 self-center">
        <base-icon
          v-if="canSeeDetails"
          size="sm"
          svg="plain/chevron-right"
          class="m-auto justify-self-center text-sm"
        />
      </div>
    </div>
  </base-link>
</template>
